import { useEffect, useState } from 'react';
import { Box, Button, Card, CardBody, CardHeader, Flex, Grid, GridItem, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import NumericInputController from 'components/Form/NumericInputController';
import { toast } from 'components/Toast';
import { SimulatorPlayGameFormValidate } from 'utils/validation';
import { useSimulatorPlayGameMutation } from 'services/game';
import { CookieStorage } from 'utils/cookie-storage';
import { useQueryGetConfigBet } from 'services/setting';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { formatNumber } from 'utils/helpers';

export default function SimulatorPlayGame() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [betConfigOptions, setBetConfigOptions] = useState([]);
  const [simulatorResult, setSimulatorResult] = useState({});
  console.log('🚀 ~ SimulatorPlayGame ~ simulatorResult:', simulatorResult);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SimulatorPlayGameFormValidate),
  });

  const { data: configBetData } = useQueryGetConfigBet({}, { enabled: CookieStorage.isAuthenticated() });
  const simulatorPlayGameMutation = useSimulatorPlayGameMutation();

  useEffect(() => {
    if (configBetData?.data) {
      setBetConfigOptions(mappingOptionSelect(configBetData?.data, 'bet_amount', 'bet_amount'));
    }
  }, [configBetData?.data]);

  const onSubmit = values => {
    simulatorPlayGameMutation.mutate(
      {
        ...values,
        bet_amount: values?.bet_amount?.value,
      },
      {
        onSuccess: response => {
          setSimulatorResult(response);
          toast.showMessageSuccess('Giả lập thành công');
        },
      }
    );
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w="100%">
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold" textTransform="uppercase">
                  QUẢN LÝ HỆ THỐNG {'>'} giả lập lượt chơi
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'} w="50%">
            <form>
              <SelectController
                control={control}
                name="bet_amount"
                label="Số tiền"
                options={betConfigOptions}
                isRequired
                menuPortalTarget={document.body}
              />
              <NumericInputController control={control} name="number_turn" label="Số lượt quay" isRequired styleContainer={{ pt: 4 }} />
            </form>
            <Flex pt="4">
              <Button colorScheme="blue" isLoading={simulatorPlayGameMutation.isPending} onClick={handleSubmit(onSubmit)}>
                Giả lập
              </Button>
            </Flex>
          </Stack>
          {simulatorResult?.total_reward && (
            <Flex alignItems="center" pt="4" gap="16px">
              <Flex alignItems="center">
                <Text fontSize="16px" fontWeight={600}>
                  Tổng tiền thắng:&nbsp;
                </Text>
                <Text fontSize="16px">{formatNumber(simulatorResult?.total_reward)}</Text>
              </Flex>
              <Flex alignItems="center">
                <Text fontSize="16px" fontWeight={600}>
                  Số lần thắng:&nbsp;
                </Text>
                <Text fontSize="16px">{simulatorResult?.winBetCount}</Text>
              </Flex>
            </Flex>
          )}
          <Grid templateColumns="repeat(2, 1fr)" gap={6} pt="4">
            {simulatorResult?.ratioSymbol && Object.values(simulatorResult?.ratioSymbol)?.map((ratioSymbolItem, index) => (
              <GridItem colSpan={{ base: 2, '2xl': 1 }} borderBottom="1px" borderColor="blue.400">
                <Text fontWeight={600}>{`Vị trí ${index + 1}:`}</Text>
                <Flex gap="12px">
                  {Object.keys(ratioSymbolItem)?.map((ratioKeyDetail, index) => (
                    <Flex flexDirection="column" key={index} gap="4px">
                      <Box>{`Symbol: ${ratioKeyDetail}`}</Box>
                      <Box>{`Số lần XH: ${ratioSymbolItem[ratioKeyDetail]?.totalTurn}`}</Box>
                      <Box>{`Tổng tỷ lệ XH: ${ratioSymbolItem[ratioKeyDetail]?.totalRate}`}</Box>
                      <Box>{`Tỷ lệ TB: ${ratioSymbolItem[ratioKeyDetail]?.ratio}`}</Box>
                    </Flex>
                  ))}
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </CardBody>
      </Card>
    </Flex>
  );
}
