import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';
import { FileExcelValid, ModalType } from 'constants/common';
import SelectController from 'components/Form/SelectController';
import { toast } from 'components/Toast';
import { useQueryGetConfigBet } from 'services/setting';
import { importConfigSymbol, useCreateConfigSymbolMutation } from 'services/symbol';
import { useQueryGetListGame } from 'services/game';
import { mappingOptionSelect } from 'utils/mapping';
import { ErrorForm } from 'constants/error';

const ImportSymbolModal = ({ gameDetailDetail, isOpen, onClose, refetch }) => {
  const inputImportRef = useRef();
  const [betConfigOption, setBetConfigOption] = useState([]);
  const [gameOptions, setGameOptions] = useState([]);
  const [fileSelected, setFileSelected] = useState(null);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        game: yup.object().nullable().required(ErrorForm.Required),
      })
    ),
    defaultValues: {
      game: undefined,
    },
  });

  const { data: configBetData } = useQueryGetConfigBet();
  const configSymbolMutation = useCreateConfigSymbolMutation();
  const { data: listGameData } = useQueryGetListGame();

  useEffect(() => {
    if (!isEmpty(configBetData)) {
      setBetConfigOption(configBetData?.data?.map(item => ({ label: `${item?.game_id?.name} - ${item?.bet_amount}`, value: item?._id })));
    }
  }, [configBetData?.data]);

  useEffect(() => {
    if (!isEmpty(listGameData?.data)) {
      setGameOptions(mappingOptionSelect(listGameData?.data, 'name'));
    }
  }, [listGameData?.data]);

  const onSubmit = async values => {
    if (fileSelected) {
      const extensionFile = fileSelected?.name?.split('.')?.pop();

      if (FileExcelValid.includes(extensionFile)) {
        const formData = new FormData();

        formData.append('importFile', fileSelected);
        formData.append('game_id', values?.game?.value);

        await importConfigSymbol(formData)
          .then(() => {
            toast.showMessageSuccess('Tải lên Symbol thành công');
            refetch();
            onClose(ModalType.Import);
          })
          .catch(() => {
            toast.showMessageError('Tải lên Symbol thất bại');
          });

        return;
      }

      setError('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
    }
  };

  const handleImportSymbols = async e => {
    setFileSelected(e?.target?.files?.[0]);
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Import);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{`Import thiết lập Symbol`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <SelectController control={control} name="game" label="Trò chơi" options={gameOptions} isRequired />
            <Box pt={4}>
              <Flex alignItems="center" gap="12px">
                <input type="file" hidden ref={inputImportRef} onChange={handleImportSymbols} />
                <Button
                  bg="#3182ce"
                  color="#fff"
                  _hover={{ bg: '#67a1d7' }}
                  onClick={() => {
                    inputImportRef?.current?.click();
                  }}
                >
                  Tải file lên
                </Button>
                {fileSelected && <Text>{fileSelected?.name}</Text>}
              </Flex>
            </Box>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Import);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={configSymbolMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ImportSymbolModal;
